import { MaterializedContentBlocksGrid } from '@fagmobler/sanity';
import { ContentBlocksRenderer } from './ContentBlocksRenderer';
import clsx from 'clsx';

type ContentBlocksGridProps = {
  columns: 1 | 2 | 3 | 4;
  content: MaterializedContentBlocksGrid['content'];
  priority?: boolean;
  fullWidth?: boolean;
};

export const ContentBlocksGrid = ({
  columns,
  content,
  priority,
  fullWidth = false,
}: ContentBlocksGridProps) => {
  const getSize = () => {
    if (columns === 1) {
      return 'lg';
    }
    if (columns === 2) {
      return 'md';
    }
    if (columns === 3) {
      return 'sm';
    }
    if (columns === 4) {
      return 'sm';
    }
    return 'md';
  };

  return (
    <div
      data-type="ContentBlocksGrid"
      data-columns={columns}
      className={clsx('relative', {
        'grid gap-4 sm:grid-cols-1': content.length > 1,
        'aspect-square md:aspect-auto md:[&>*]:aspect-[2/1]': columns === 1,
        '[&>*]:aspect-video sm:[&>*]:aspect-square': columns === 2,
        'grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2':
          columns === 2,
        'grid-cols-1 md:grid-cols-3 lg:grid-cols-3 [&>*]:aspect-video sm:[&>*]:aspect-square [&>*]:w-full':
          columns === 3,
        'grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 [&>*]:aspect-video md:[&>*]:aspect-square':
          columns === 4,
        '[&>a>div]:max-w-full': !fullWidth,
        'relative w-screen ml-[-50vw] left-1/2 !-mt-0': fullWidth,
      })}
    >
      <ContentBlocksRenderer
        blocks={content}
        getSize={getSize}
        priority={priority}
        fullWidth={fullWidth}
      />
    </div>
  );
};
