import { useMemo } from 'react';
import clsx from 'clsx';
import type { OmniumStore } from '@fagmobler/omnium';
import { add, differenceInDays, format } from 'date-fns';
import { nb } from 'date-fns/locale';

export type StoreOpeningHoursProps = {
  store: OmniumStore;
  compact?: boolean;
  className?: string;
};

type OpeningHoursKey =
  | 'mondayOpeningHours'
  | 'tuesdayOpeningHours'
  | 'wednesdayOpeningHours'
  | 'thursdayOpeningHours'
  | 'fridayOpeningHours'
  | 'saturdayOpeningHours'
  | 'sundayOpeningHours';

const weekdays = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

const weekdaysTranslated = {
  monday: 'Mandag',
  tuesday: 'Tirsdag',
  wednesday: 'Onsdag',
  thursday: 'Torsdag',
  friday: 'Fredag',
  saturday: 'Lørdag',
  sunday: 'Søndag',
};

const weekdayToday = weekdays[(new Date().getDay() - 1) % 7];

/**
 * Format time to only include hours and minutes.
 *
 * @param {string} openingHours [HH:mm:ss]
 *
 * @returns {string} [HH:mm]
 */
function fmtHours(openingHours: string) {
  const [hours, minutes] = openingHours.split(':');
  return `${hours}:${minutes}`;
}

export function useOpeningHours(
  store?: OmniumStore | null,
  showAdditionalOpeningHours = false
) {
  return useMemo(() => {
    const openingHours: Record<string, string> = {};

    if (!store) {
      return openingHours;
    }
    let currentTime: string | null = null;
    let currentDayFrom = '';
    let currentDayTo = '';

    for (const weekday of weekdays) {
      const key = `${weekday}OpeningHours` as OpeningHoursKey;

      const from = fmtHours((store[key]?.openFrom as string) ?? '');
      const to = fmtHours((store[key]?.openTo as string) ?? '');

      const time = `${from} - ${to}`;

      if (!currentTime) {
        currentTime = time;
        currentDayFrom =
          weekdaysTranslated[weekday as keyof typeof weekdaysTranslated];
      }

      if (time !== currentTime) {
        openingHours[
          currentDayFrom === currentDayTo
            ? currentDayFrom
            : `${currentDayFrom} til ${currentDayTo}`
        ] = currentTime;

        currentTime = store[key]?.isOpen ? time : currentTime;
        currentDayFrom = store[key]?.isOpen
          ? weekdaysTranslated[weekday as keyof typeof weekdaysTranslated]
          : currentDayFrom;
      }

      currentDayTo = store[key]?.isOpen
        ? weekdaysTranslated[weekday as keyof typeof weekdaysTranslated]
        : currentDayTo;
    }

    if (showAdditionalOpeningHours && store.additionalOpeningHours) {
      const filteredAdditionalOpeningHours = store.additionalOpeningHours

        ?.sort((a, b) => {
          if (a.date && b.date) {
            return a.date > b.date ? 1 : -1;
          }
          return -1;
        })
        .filter((item) => {
          if (!item.date) return false;
          const now = new Date();
          const daysFromNow = differenceInDays(new Date(item.date), now);
          return daysFromNow >= -2 || daysFromNow <= 31;
        })
        .map((item) => {
          if (item.date) {
            const dateParts = item.date.split('T');
            const daysToAdd = dateParts[1].startsWith('00') ? 0 : 1;

            return {
              ...item,
              date: add(new Date(item.date.split('T')[0]), { days: daysToAdd }),
            };
          }
        })
        .filter(Boolean);

      let acc: any[] = [];

      filteredAdditionalOpeningHours.forEach((item, i) => {
        const now = new Date();
        if (!item) return;
        const isLastItem = filteredAdditionalOpeningHours.length === i + 1;
        if (item.date) {
          const daysFromNow = differenceInDays(new Date(item.date), now);
          if (daysFromNow <= -2 || daysFromNow >= 31) return;

          const nextItem = filteredAdditionalOpeningHours[i + 1];

          const nextDayHasTheSameOpeningHours = !!(
            (nextItem?.date &&
              nextItem.closed === true &&
              item.closed === true) ||
            (JSON.stringify(item.openingHours) ===
              JSON.stringify(nextItem?.openingHours) &&
              nextItem &&
              differenceInDays(new Date(nextItem.date), new Date(item.date)) ===
                1)
          );

          acc.push(item);

          if (nextDayHasTheSameOpeningHours && !isLastItem) {
            return;
          }

          const key =
            acc.length > 1
              ? `${format(new Date(acc[0].date), 'do MMM', {
                  locale: nb,
                })} til ${format(new Date(acc[acc.length - 1].date), 'do MMM', {
                  locale: nb,
                })}`
              : format(new Date(item.date), 'do MMM', {
                  locale: nb,
                });

          acc = [];
          openingHours[key] = item.closed
            ? `stengt`
            : `${fmtHours(item.openingHours?.openFrom as string)} - ${fmtHours(
                item.openingHours?.openTo as string
              )}`;
        }
      });
    }
    return openingHours;
  }, [store, showAdditionalOpeningHours]);
}

/**
 * StoreOpeningHoursDetailed
 *
 * @returns {JSX}
 */
export function StoreOpeningHoursDetailed({
  store,
  className,
}: {
  store: OmniumStore;
  className?: string;
}) {
  const openingHours = useOpeningHours(store, true);

  return JSON.stringify(openingHours || {}) !== '{}' ? (
    <div className={clsx('mt-5', 'mb-2', className)}>
      detailed
      <h3 className={clsx('mb-2')}>Åpningstider</h3>
      {Object.entries(openingHours).map(([key, value]) => (
        <p key={key} className={clsx('flex', 'justify-between')}>
          <span>{key}</span>
          <span>{value}</span>
        </p>
      ))}
      {/* {store.additionalOpeningHours.map((item) => {
        return <pre>{item}</pre>;
      })} */}
    </div>
  ) : null;
}

/**
 * StoreOpeningHoursCompact
 *
 * @returns {JSX}
 */
export function StoreOpeningHoursCompact({
  store,
  className,
}: {
  store: OmniumStore;
  className?: string;
}) {
  const openingHoursTodayKey = `${weekdayToday}OpeningHours` as OpeningHoursKey;

  const today = store[openingHoursTodayKey];

  let value = 'Stengt i dag';

  if (today?.isOpen) {
    const from = fmtHours((today?.openFrom as string) ?? '');
    const to = fmtHours((today?.openTo as string) ?? '');

    value = `Åpent i dag (${from}-${to})`;
  }

  return (
    <p className={clsx('mt-2', className)}>
      Compact
      {value}
    </p>
  );
}

/**
 * StoreOpeningHours component.
 *
 * @returns {JSX}
 */
export function StoreOpeningHours({
  store,
  compact = true,
  className,
}: StoreOpeningHoursProps) {
  if (compact) {
    return <StoreOpeningHoursCompact store={store} className={className} />;
  }

  return <StoreOpeningHoursDetailed store={store} className={className} />;
}

export default StoreOpeningHours;
