import {
  MaterializedInternalLink,
  internalLinkProjection,
  imageWithCropProjection,
  MaterializedImageWithCrop,
} from '../../materializedTypes';
import { SanityKeyed, TopBanner } from '../../schemaTypes';
import groq from 'groq';

export type MaterializedTopBanner = SanityKeyed<
  TopBanner & {
    internalLink?: MaterializedInternalLink;
    countdownImage?: MaterializedImageWithCrop;
  }
>;

export const topBannerProjection = groq`
  ...,
  countdownImage {${imageWithCropProjection}},
  internalLink {${internalLinkProjection}},
`;
